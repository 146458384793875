<template>
  <div>
    <v-form ref="form" @submit.prevent="checkInput">
      <v-row>
        <v-col>
          <v-card-actions>
            <v-btn v-if="this.checkPermission([128, 8])" :loading="loading" class="white--text" type="submit" color="red"> Сохранить </v-btn>

            <v-spacer></v-spacer>
            <UploadPictureDialog v-if="this.checkPermission([128, 8])" :pictureType="'Cafe'" />
          </v-card-actions>
          <v-card-text>
            <div v-if="!!picture" class="d-flex justify-center">
              <v-img :src="url() + this.picture.path" alt="Изображение" max-height="300" max-width="400" />
            </div>
            <v-row>
              <v-col>
                Название
                <v-text-field
                  :disabled="!this.checkPermission([128, 8])"
                  v-model="currentCafeInfo.nameRu"
                  background-color="white"
                  class="mt-2"
                  dense
                  outlined
                />
                Адрес
                <v-text-field
                  :disabled="!this.checkPermission([128, 8])"
                  v-model="currentCafeInfo.addressRu"
                  background-color="white"
                  class="mt-2"
                  dense
                  outlined
                />
                Корпоративный
                <v-select
                  :disabled="!this.checkPermission([128, 8])"
                  v-model="currentCafeInfo.onlyCorpo"
                  background-color="white"
                  class="mt-2"
                  dense
                  outlined
                  :items="isActivePropertyList()"
                />
                <span>Заблокировано суперадмином</span>
                <v-select
                  :items="isActivePropertyList()"
                  :disabled="!this.checkPermission([128])"
                  v-model="currentCafeInfo.disabledBySuperior"
                  background-color="white"
                  class="mt-2"
                  dense
                  outlined
                />
                Время открытия
                <v-text-field
                  :disabled="!this.checkPermission([128, 8])"
                  v-model="currentCafeInfo.operTimeStart"
                  background-color="white"
                  class="mt-2"
                  dense
                  outlined
                />
                Время закрытия
                <v-text-field
                  :disabled="!this.checkPermission([128, 8])"
                  v-model="currentCafeInfo.operTimeEnd"
                  background-color="white"
                  class="mt-2"
                  dense
                  outlined
                />
                Приблизительное время приготовления
                <v-text-field
                  :disabled="!this.checkPermission([128, 8])"
                  v-model.number="currentCafeInfo.approxTimeCook"
                  background-color="white"
                  class="mt-2"
                  dense
                  outlined
                  hide-spin-buttons
                />

                Название организации
                <v-text-field
                  :disabled="!this.checkPermission([128, 8])"
                  v-model="currentCafeInfo.agentName"
                  background-color="white"
                  class="mt-2"
                  dense
                  outlined
                />
                ИНН
                <v-text-field
                  :disabled="!this.checkPermission([128, 8])"
                  v-model="currentCafeInfo.agentInn"
                  background-color="white"
                  class="mt-2"
                  dense
                  outlined
                />
                Телефон
                <v-text-field
                  :disabled="!this.checkPermission([128, 8])"
                  v-model="currentCafeInfo.agentPhone"
                  background-color="white"
                  class="mt-2"
                  dense
                  outlined
                />
              </v-col>
              <v-col>
                <div class="d-flex my-3">
                  <span>Станции</span>
                  <v-spacer></v-spacer>
                  <v-btn v-if="checkPermission([128, 8])" @click="dialogStation = true" color="red" class="white--text">
                    <v-icon left>mdi-plus</v-icon>Добавить
                  </v-btn>
                </div>
                <v-data-table :headers="headerStations" :items="linkStations" disable-pagination hide-default-footer class="ma-3">
                  <template v-slot:item.actionStation="{ item }">
                    <v-btn v-if="checkPermission([128, 8])" @click="deleteStation(item.token)" color="red" text>
                      <v-icon left>mdi-delete-outline</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
                <v-divider></v-divider>
                <div class="d-flex my-3">
                  <span>Точки выдчи</span>
                  <v-spacer></v-spacer>
                  <v-btn v-if="checkPermission([128, 8])" @click="dialogTakeout = true" color="red" class="white--text">
                    <v-icon left>mdi-plus</v-icon>Добавить
                  </v-btn>
                </div>
                <v-data-table :headers="headerTakeouts" :items="linkTakeouts" disable-pagination hide-default-footer class="ma-3">
                  <template v-slot:item.actionTakeout="{ item }">
                    <v-btn v-if="checkPermission([128])" @click="deleteTakeout(item.token)" color="red" text>
                      <v-icon left>mdi-delete-outline</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-form>
    <v-dialog v-model="dialogStation" max-width="600">
      <v-card>
        <v-card-title>Выберите станцию</v-card-title>
        <v-data-table :headers="headerStations" :items="stations" disable-pagination hide-default-footer>
          <template v-slot:item.actionStation="{ item }">
            <v-btn text color="red" @click="addCafeStationClick(item.token)">
              <v-icon left>mdi-plus</v-icon>
              Добавить</v-btn
            >
          </template>
        </v-data-table>
        <v-card-actions>
          <v-btn color="red" class="white--text my-1 ml-auto" @click="dialogStation = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogTakeout" max-width="600">
      <v-card>
        <v-card-title>Выберите точку выдачи</v-card-title>
        <v-data-table :headers="headerTakeouts" :items="takeouts" disable-pagination hide-default-footer>
          <template v-slot:item.actionTakeout="{ item }">
            <v-btn text color="red" @click="addCafeTakeoutClick(item.token)">
              <v-icon left>mdi-plus</v-icon>
              Добавить</v-btn
            >
          </template>
        </v-data-table>
        <v-card-actions>
          <v-btn color="red" class="white--text my-1 ml-auto" @click="dialogTakeout = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="notification">
      <Notification />
    </div>
  </div>
</template>
<script>
import Notification from '@/components/Notification';
import UploadPictureDialog from '@/components/food/UploadPictureDialog';
import config from '@/config/config';
import ReferenceHelper from '@/helpers/reference/reference.helper';

import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      editedCafe: {
        nameRu: '',
        addressRu: '',
        openTimeStart: '',
        openTimeEnd: '',
        approxTimeCook: '',
        onlyCorpo: null,
        agentName: '',
        agentInn: '',
        agentPhone: ''
      },
      headerStations: [
        { text: 'Название', value: 'nameExpFullRu' },
        { text: '', value: 'actionStation' }
      ],
      headerTakeouts: [
        { text: 'Название', value: 'nameFullRu' },
        { text: '', value: 'actionTakeout' }
      ],
      dialogTakeout: false,
      dialogStation: false,
      choosenStation: [],
      choosenTakeout: [],
      loading: false
    };
  },
  components: { Notification, UploadPictureDialog },
  computed: {
    currentCafeInfo() {
      if (!Object.keys(this.cafeInfo).length) return {};
      return JSON.parse(JSON.stringify(this.cafeInfo));
    },
    linkStations() {
      if (!Object.keys(this.cafeInfo).length && !this.menuListResult) return [];
      return JSON.parse(JSON.stringify(this.cafeInfo.stations));
    },
    linkTakeouts() {
      if (!Object.keys(this.cafeInfo).length && !this.menuListResult) return [];
      return JSON.parse(JSON.stringify(this.cafeInfo.takeouts));
    },
    stations() {
      if (!this.stationsList) return [];
      let array = [];
      this.stationsList.forEach((station) => {
        if (this.linkStations.find((st) => st.token === station.token) === undefined) array.push(station);
      });
      return array;
    },
    takeouts() {
      if (!this.takeoutsData) return [];
      let array = [];
      this.takeoutsData.forEach((takeout) => {
        if (this.linkTakeouts.find((tk) => tk.token === takeout.token) === undefined) array.push(takeout);
      });
      return array.filter((takeout) => this.linkStations.some((station) => station.token === takeout.station.token));
    },
    ...mapGetters('takeouts', ['takeoutsData']),
    ...mapGetters('station', ['stationsList']),
    ...mapGetters('auth', ['userPerm']),
    ...mapGetters('picture', ['picture']),
    ...mapGetters('admin', ['cafeInfo']),
    ...mapGetters('admin', ['editCafeErrorMessage']),
    ...mapGetters('admin', ['menuListResult'])
  },
  methods: {
    checkPermission(value) {
      return this.userPerm.find((el) => value.includes(el)) !== undefined;
    },
    isActivePropertyList() {
      return ReferenceHelper.getActiveProperty();
    },
    checkInput() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.submitData();
      }
    },
    submitData() {
      this.currentCafeInfo.cafeToken = this.currentCafeInfo.token;
      if (this.picture !== undefined && this.picture !== null) {
        this.currentCafeInfo.img = this.picture.path;
        this.currentCafeInfo.imageId = this.picture.id;
      }
      this.editCafe(this.currentCafeInfo)
        .then(() => {
          this.loading = false;
          this.showNotification('Кафе успешно изменено', 'success', '2000', 'mdi-check');
          this.getCafesProduct();
        })
        .catch(() => {
          this.loading = false;
          this.showNotification(`Произошла ошибка: ${this.editCafeErrorMessage}`, 'red', '5000', 'mdi-check');
        });
    },
    deleteStation(token) {
      this.deleteCafeStation({ stationToken: token, cafeToken: this.$route.params.cafeToken }).then(() => {
        this.getStations();
        this.getCafesProduct();
      });
    },
    addCafeStationClick(token) {
      this.addCafeStation({ stationToken: token, cafeToken: this.$route.params.cafeToken })
        .then(() => {
          this.getStations();
          this.getCafesProduct();
          this.dialogStation = false;
        })
        .catch(() => {
          this.loading = false;
          this.showNotification(`Произошла ошибка`, 'red', '5000', 'mdi-check');
        });
    },
    deleteTakeout(token) {
      this.deleteCafeTakeout({ takeoutToken: token, cafeToken: this.$route.params.cafeToken }).then(() => {
        this.getStations();
        this.getCafesProduct();
      });
    },
    addCafeTakeoutClick(token) {
      this.addCafeTakeout({ takeoutToken: token, cafeToken: this.$route.params.cafeToken })
        .then(() => {
          this.getStations();
          this.getCafesProduct();
          this.dialogTakeout = false;
        })
        .catch(() => {
          this.loading = false;
          this.showNotification(`Произошла ошибка`, 'red', '5000', 'mdi-check');
        });
    },
    showNotification(message, color, timer, icon) {
      this.showSnack({
        message,
        color,
        timer,
        icon
      });
    },
    url() {
      return window.location.origin + '/';
    },
    getCafesProduct() {
      this.fetchMenuList(this.$route.params.cafeToken);
    },
    getTakeouts() {
      this.fetchPermissionTakeoutsList();
    },
    getStations() {
      this.getPermissionsStations();
    },
    beforeDestroy() {
      this.destroyProductPicture();
    },
    ...mapActions('admin', ['editCafe']),
    ...mapActions('cafe', ['addCafeStation']),
    ...mapActions('cafe', ['deleteCafeStation']),
    ...mapActions('picture', ['destroyProductPicture']),
    ...mapActions('notification', ['showSnack']),
    ...mapActions('admin', ['fetchMenuList']),
    ...mapActions('station', ['getPermissionsStations']),
    ...mapActions('takeouts', ['fetchPermissionTakeoutsList']),
    ...mapActions('takeouts', ['addCafeTakeout']),
    ...mapActions('takeouts', ['deleteCafeTakeout'])
  },
  mounted() {
    this.getTakeouts();
    this.getStations();
  },

  watch: {
    currentMenu(newVal) {
      if (newVal !== null) {
        this.editedCafe = newVal;
      }
    }
  }
};
</script>
<style scoped>
video {
  border-radius: 10px;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  height: 50px;
  padding: 0 12px;
}

.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
  height: 40px;
  padding: 0 6px;
}
</style>
