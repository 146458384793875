<template>
  <div class="text-right">
    <v-dialog v-model="dialog" max-width="400">
      <template v-slot:activator="{ on }">
        <v-btn color="red" class="white--text my-1" v-on="on">
          <v-icon>mdi-text-box-plus-outline</v-icon>
          Изменить изображение
        </v-btn>
      </template>
      <v-card class="file-upload-card px-4" outlined>
        <v-form ref="fileForm" enctype="multipart/form-data" @submit.prevent="checkInput">
          <v-card-title>Загрузить изображение</v-card-title>
          <v-card-text>
            <span class="font-weight-medium">Формат: jpg</span>
            <v-file-input v-model="file" :rules="fileRules" :show-size="1000" hide-details="auto" class="mt-1" single-line outlined dense>
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-card-text>
          <v-card-actions class="mb-2">
            <v-spacer></v-spacer>
            <v-btn :loading="loading" type="submit" class="white--text my-1" color="red" depressed>Загрузить</v-btn>
            <v-btn text class="mx-2 my-1 text--none" color="grey darken-3" @click="closeDialog">Отмена</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      file: null,
      fileRules: [(v) => !!v || 'Обязательное поле']
    };
  },
  props: {
    pictureType: {
      type: String
    }
  },
  methods: {
    checkInput() {
      if (this.$refs.fileForm.validate()) {
        this.loading = true;

        this.submitData();
      }
    },
    submitData() {
      const formData = new FormData();
      formData.append('file', this.file);
      this[`upload${this.pictureType}Picture`](formData)
        .then(() => {
          this.loading = false;
          this.closeDialog();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    closeDialog() {
      this.dialog = false;
      this.$refs.fileForm.reset();
      this.file = null;
    },
    ...mapActions('picture', ['uploadProductPicture']),
    ...mapActions('picture', ['uploadCafePicture']),
    ...mapActions('picture', ['uploadCategoryPicture']),
    ...mapActions('picture', ['uploadTagPicture'])
  }
};
</script>

<style scoped>
.file-upload-card .v-btn {
  font-weight: 400;
}
</style>
